.title-line{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width:767px) {
    padding:0 1.5rem;
    flex-wrap: wrap;
  }
}
.wrapper{
  @media (max-width:767px) {
    flex-direction: column;
    padding:0 1.5rem;
  }
  .details{
    display: flex;
    flex-direction: column;
    flex:1 1 66%;
    @media (max-width:767px) {
      flex:1 1 100%;
      width: 100%;
      &.mr6{
        margin-right: 0;
      }
    }
    .label{
      @include ptype-3;
      color:$text-light-01;
    }
    .magic-input .inputContainer textarea{
      padding-top: 8px;
      min-height: 250px;
      @media (max-width:767px) {
        min-height: 80px;
      }
    }
    svg{
      
      + .ptype-5-strong{margin-left: 1rem;}
      rect{
        fill:black !important;
      }
    }
  }
  .status{
    flex:1 1 33%;
    max-width: 506px;
    min-width:400px;
    @media (max-width:767px) {
      flex:1 1 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 1.5rem;
      min-width:300px;
    }
    .arhivare{
      &.orange{
        border: 1px solid #FF832B;
        .btn-arhiva{
          background-color:#FF832B;
        }
      }
      &.red{
        border: 1px solid #DA1E28;
        .btn-arhiva{
          background-color:#DA1E28;
        }
      }
    }
  }
  .document{
    >.flex-sb{
      justify-content: flex-start;
    }
  }
}

.arhivare{
  @media (max-width:767px) {
    &.p6{
      padding: calc(3 * 0.25rem);
    }
  }
  @media (max-width:400px) {
    .hide--400{
      display: none;
    }
  }
  .content-wrap{
    margin-top: -1.5rem;
    color:$text-light-01;
    .left-side{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .title{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
    }
    .content{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.01em;
    }
  }
  .btn-arhiva{
    user-select: none;
    padding: 4px 24px;
    border:0;
    outline:none;
    color:$white;
    display: flex;
    align-items: center;
    min-height: 48px;
    cursor: pointer;
    >svg{
      margin-right:0.5rem;
      margin-left: -0.75rem;
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.flex.jcsb{
  width: 100%;
  justify-content: space-between;
}

.produse{
  .subtitle{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color:$text-light-01;
    margin-bottom: 0.5rem;
  }
  .outline-box{
    display: flex;
    width: 100%;
    min-height: 48px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f3f3f3;
    padding: 0 12px;
    box-sizing:border-box;
    &:hover{
      border-color: #f3f3f3;
    }
    .left{
      svg{
        margin-right: 0.5rem;
      }
    }
  }
}
