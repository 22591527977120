// @import "../../style/colors";
// @import "../../style/typography";

// @mixin button-general{
//   cursor: pointer;
//   position: relative;
//   .badge{
//     color: $text-light-01;
//     position: absolute;
//     top: 25%;
//     right: 25%;
//     border-radius: 4px;
//     min-width: 1rem;
//     background: $white;
//     text-align: center;
//     @include ptype-3;
//     font-weight: 700;
//     border: 1px solid $text-light-02;
//   }
//   &.selected{
//     .badge{
//       border: none;
//     }
//   }
// }
// %btnPrimary {
//   @include button-general;
//   background-color: $button-prime;
//   color: $grey-10;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   @extend %etype-1;
//   text-overflow: clip;
//   white-space: nowrap;
//   stroke: $text-light-01;
//   &:hover {
//     background-color: $hover-button-prime;
//   }
//   &:active {
//     background-color: $click-button-prime;
//   }
//   &.disabled{
//     background-color: $text-light-03;
//   }
// }

// %btnPrimaryOutline {
//   @include button-general;
//   color: $button-prime;
//   border: 2px solid $button-prime;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   @extend %etype-1;
//   text-overflow: clip;
//   stroke: $text-light-01;

//   white-space: nowrap;
//   &:hover {
//     color: $hover-button-prime;
//     border: 2px solid $hover-button-prime;
//   }
//   &:active {
//     color: $click-button-prime;
//     border: 2px solid $click-button-prime;
//   }
// }

// %btnSecondary {
//   @include button-general;
//   background-color: $button-secondary;
//   color: $grey-10;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   stroke: $text-light-01;

//   &:hover {
//     background-color: $hover-button-secondary;
//   }
//   &:active {
//     background-color: $click-button-secondary;
//   }
// }

// %btnFocus {
//   @include button-general;
//   background-color: $button-focus;
//   color: $grey-10;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   stroke: $text-light-01;

//   &:hover {
//     background-color: $hover-button-focus;
//     stroke: $white;
//   }
//   &:active {
//     background-color: $click-button-focus;
//     stroke: $white;

//   }
// }

// %btnGrey {
//   @include button-general;
//   background-color: $grey-40;
//   color: $grey-10;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   stroke: $text-light-01;

//   &:hover {
//     background-color: lighten($grey-40, 5%);
//   }
//   &:active {
//     background-color: darken($grey-40, 10%);
//   }
// }


// %btnGreen {
//   @include button-general;
//   // width: 100%;
//   background-color: $green-40;
//   color: $white;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.2s ease-out;
//   stroke: $text-light-01;

//   @extend %etype-1;
//   &:hover {
//       background-color: lighten($green-40, 10%);
//   }
//   &:active {
//       background-color: darken($green-40, 10%);
//   }
// }


// .buttonContainerMainCTA {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   @extend %btnPrimary;
//   stroke: $text-light-01;

//   &.disabled{
//     pointer-events: none;
//     user-select: none;
//   }

//   .label {
//     padding-left: 1rem; //temp fix
//     flex: 1;
//   }
// }

// .buttonContainerSecondary {
//   display: flex;
//   align-items: center;
//   @extend %btnSecondary;
//   stroke: $text-light-01;

//   .label {
//     padding-left: 1rem; //temp fix
//     flex: 1;
//   }
// }

// .buttonContainerGreen {
//   display: flex;
//   align-items: center;
//   @extend %btnGreen;
//   stroke: $text-light-01;

//   .label {
//     // padding-left:1rem; //temp fix
//     flex: 1;
//   }
// }

// .buttonContainerGrey {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   @extend %btnGrey;
//   stroke: $text-light-01;
//   background-color: $grey-10;
//   &.close-icon{
//     svg{
//       width: 46px;
//       height: 46px;
//       path{
//         fill: none;
//         stroke:transparent;
//         ~ path{
//           stroke: #939393;
//         }
//       }
//     }
//   }
//   &.blue{
//     stroke: $button-prime;
//     color: $button-prime;
//     @include ptype-5;
//     stroke-width: 1;
//     &:hover{
//       font-weight: bold;
//       stroke-width: 1.5;
//     }
//   }
//   &.red{
//     stroke: $button-focus;
//   }
//   .label {
//     padding-left: 1rem; //temp fix
//     flex: 1;
//   }
//   // &:hover:not(.selected){
//   //   stroke-width: 1.5;
//   // }
//   &.selected{
//     background-color: $button-prime;
//     stroke: $white;
//   }
// }




// $tipSharpness: 0.8rem;
// // .button-tool-tip-right {
// //   position: relative;

// //   &:after {
// //     pointer-events : none;
// //     content: attr(tooltip);
// //     /* here's the magic */
// //     position: absolute;
// //     /* vertically center */
// //     top: 50%;
// //     left: 100%;
// //     transform: translate(0em, -50%);
// //     /* move to right */
// //     padding: 0.5rem 0.75rem 0.5rem 1rem;
// //     background: $text-light-01;
// //     clip-path: polygon($tipSharpness 0%,100% 0%, 100% 100%,$tipSharpness 100%, 0% 50% );
// //     color: $white;
// //     @extend %ptype-4-price;
// //     white-space: nowrap;

// //     // display: hidden;
// //     opacity: 0;
// //     transition: all 0.3s ease-out;
// //     z-index: 10;
// //   }
// //   &:hover:after {
// //     display: block;
// //     opacity: 1;
// //   }
// // }

// // .button-tool-tip-left {
// //   position: relative;

// //   &:after {
// //     pointer-events : none;
// //     content: attr(tooltip);
// //     /* here's the magic */
// //     position: absolute;
// //     /* vertically center */
// //     top: 50%;
// //     right: 100%;
// //     transform: translate(0em, -50%);
// //     /* move to right */
// //     padding: 0.5rem 1rem 0.5rem 0.75rem;
// //     background: $text-light-01;
// //     clip-path: polygon(0% 0%,calc(100% - #{$tipSharpness}) 0%,100% 50%,calc(100% - #{$tipSharpness}) 100%, 0% 100%);
// //     color: $white;
// //     @extend %ptype-4-price;
// //     white-space: nowrap;

// //     // display: hidden;
// //     opacity: 0;
// //     transition: all 0.3s ease-out;
// //     z-index: 10;
// //   }
// //   &:hover:after {
// //     display: block;
// //     opacity: 1;
// //   }
// // }

// .buttonContainerOutline {
//   display: flex;
//   align-items: center;
//   @extend %btnPrimaryOutline;
//   .label {
//     padding-left: 1rem; //temp fix
//     flex: 1;
//   }
//   &.grey{
//     border-color: $grey-10;
//     background-color: $white;
//     color: $text-light-01;
//     .label{
//       padding-left: 0;
//       white-space: nowrap;
//       padding: 0 1.5rem;
//     }
//     &:hover{
//       background-color: lighten($hover-button-secondary, 10%);
//     }
//     &:active{
//       background-color: lighten($hover-button-secondary, 0%);
//     }
//   }
// }

// .buttonSimple {
//   @include button-general;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // cursor: pointer;
//   stroke: #333;
//   &:hover:not(.selected){
//     stroke-width: 1.5;
//   }
//   &.selected{
//     background-color: $button-prime;
//     &.active-green{
//       background-color: $green-40;
//     }
//     stroke: $white;
//   }
// }

// .click {
//   cursor: pointer;
// }
// .buttonWhite {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 2px solid #333333;
//   box-sizing: border-box;
//   padding: 2rem;
//   color: black;
//   @extend %btnGrey;
// }

.buttonSimple{
	&.selected{
		svg{
			path{
				stroke:#fff;
			}
		}
	}
}