
// @import "style/colors";

.redux-toastr{

  .top-left, 
  .top-right, 
  .top-center, 
  .bottom-left, 
  .bottom-right, 
  .bottom-center {
    width: 100%;
    max-width: 444px;
    padding: 0;
    @media (max-width:550px) {
      max-width:calc(100% - 20px);
    }
  }
  .bottom-left{
    left:88px;
    bottom:10px;
    @media (max-width:550px) {
      left:10px;
      bottom:10px;
    }
  }

  .toastr{
    min-height:98px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width:550px) {
      margin: 10px 0 0;
      min-height:120px;
    }
    &.rrt{
      &-success {
        background-color:$white;
        border: 1px solid $all-good;
        border-left: 8px solid $all-good;
      }
      &-warning {
        background-color: #ffffff;
        border: 1px solid #FF832B;
        border-left: 8px solid #FF832B;
      }
      &-error {
        background-color: #ffffff;
        border: 1px solid #DA1E28;
        border-left: 8px solid #DA1E28;
      }
    }

    .rrt{
      &-left-container{
        display: none;
      }
      &-middle-container{
        display: flex;
        flex-direction: column;
        padding:16px 0 16px 16px;
        width: auto;
        max-width: calc(100% - 80px);
        margin:0;
        min-height: 96px;
        box-sizing: border-box;
        align-items:flex-start;
        justify-content:center;
        @media (max-width:550px) {
          min-height:118px;
        }
        .rrt-title{
          display: flex;
          font-family: 'Open Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color:#333333;
        }
        .rrt-text{
          display: flex;
          margin: 0;
          font-family: 'Open Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0em;
          color:#333333;
        }
        .rrt-date{
          display: flex;
          margin-top: 8px;
          font-family: 'Open Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
          letter-spacing: -0.02em;
          text-align: left;
          color:#B3B3B3;
        }
      }
      &-right-container{
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 96px;
        .close-toastr{
          width: 32px;
          height: 32px;
          background-color: #ECF5FF;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transform: translateY(-50%);
          font-size: 14px;
          opacity: 1;
          color:#333333;
          >span{
            display: flex;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 32px;
            color:#333333;
          }
        }
      }
    }
  }
}