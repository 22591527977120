@import 'style/typography.scss';
@import 'style/colors.scss';
@import 'style/_media.scss';

.article{
  margin-top: -5px;
  position: relative;
  padding:2.5rem;
  flex-grow: 1 ;
  @include max-MD{
    padding:1.5rem;
  }
  .subtitle{
    margin:0;
    padding:0;
    @extend %ptype-4-price;
    text-transform: uppercase;
    color: $text-light-03;

  }
  .title{
    margin:0 0 1rem;
    padding:0;
    @extend %ptype-5-strong;
    color: $text-light-01;
  }
  p{
    @extend %ptype-6;
    color: $text-light-01;
    margin:0;
    @include max-MD{
      font-size: 16px;
    }
  }
  .bottom-container{
    display: flex;
    margin-right: -1.5rem !important;
    @include max-S{
      flex-direction: column;
      margin-right: 0rem !important;
    }
  }
  .button-container {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    @include max-S{
      margin-right: -1.5rem !important;
    }
    @include only-XS{
      flex-wrap: wrap;
      width: 5rem;
    }
    .buttonSimple{
      margin-bottom: -1.5rem !important;
    }
  }
}