@mixin wrapper-spacing {
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-bottom: 5rem;

    @include only-S {
        padding: 0 3rem;
    }
    @include only-XS {
        $padding: 1rem;
        > div + div {
            margin-top: $padding;
        }
        .col {
            &.left,
            &.right {
                width: calc(100vw - 2 * #{$padding});
                > div + div {
                    margin-top: $padding;
                }
            }
        }
        flex-direction: column;
        padding: $padding;
    }
}

@mixin responsive-etype-6 {
    @include etype-6;

    @include max-MD {
        @include etype-5;
    }
    @include max-S {
        @include etype-3;
    }
}
@mixin responsive-ptype-6 {
    @include ptype-6;

    @include max-S {
        @include ptype-5;
    }
}

@mixin responsive-card-padding4rem {
    padding: 4rem;

    @include max-S {
        padding: 1.5rem;
    }
}

@mixin guest-page-spacing {
    padding: 5rem;
    padding-top: 2rem;

    max-width: 1550px;
    margin-left: auto;
    margin-right: auto;
    // grid-column: 1/3 ;
    @include max-LG {
        padding: 3rem;
        padding-top: 2rem;
    }
    @include only-S {
        padding: 3.5rem;
        padding-top: 2rem;
    }
    @include only-XS {
        padding: 2rem;
    }
}

@mixin greyLabel {
  background-color: #f3f3f3;
  padding: 12px 24px;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

@mixin icon-box-40{
    cursor: pointer;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 40px;
    height: 40px;

}

@mixin action-color-hover {
    &:hover {
        svg{
            stroke-width: 1.5px;
        }
        p{
            color: $black;
        }
    }

}

@mixin scrollbar($size,$radius,$track,$thumb) {  // Size (horiz,vert) , BorderRadius, TrackColor, ThumbColor;
    &::-webkit-scrollbar {
        height:$size;
    }
    &::-webkit-scrollbar-track {
        background-color: $track;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $thumb;
        border-radius: $radius;
    }
}