@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";


@for $i from 0 through 20 {
  .menu-search-wrap .drop-list.opened .listblock ul li:nth-child(#{$i + 1}) {
    transition: all 0.35s ease-out;
    transition-delay: 1s * $i;
  }
}
