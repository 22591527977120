.navBar{
  display: flex;
  justify-content: space-between;
  background-color: rgba(255,255,255,0);
  position: fixed;
  z-index: 99;
  width: 100%;
  stroke:$white;
  transition: all 300ms ease-in-out;
  &.white{
    background-color: rgba(255,255,255,1);
    stroke: $text-light-01;
    .menuBtn,.homeBtn{
      svg{
        stroke:$text-light-01;
        transition: all 300ms ease-in-out;
      }
    }
  }

  .hamburger{
    margin: 0rem;
    height: 4rem;
    width: 4rem;
    flex: 0 0 4rem;
    white-space: break-spaces;
    background-color:transparent;
    border: 0;
    outline: none;
    position: relative;
    transition: all 0.25s ease-out;
    cursor: pointer;
    &:active{
      background-color:transparent;
    }
    &.white:not(.active){
      > span{
        background-color: white;
        &::before,
        &::after{
          background-color: #ffffff;
        }
      }
      

    }
    > span{
      background-color: #939393;
      width: 22px;
      height: 2px;
      display: block;
      position: absolute;
      pointer-events: none;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      transition: all 0.25s ease-out;
      &::before,
      &::after{
        content:'';
        background-color: #939393;
        width: 22px;
        height: 2px;
        display: block;
        border-radius: 1px;
        transition: all 0.25s ease-out;
      }
      &::before{
        transform: translateY(-7px);
        transform-origin: 3px 4px;
      }
      &::after{
        transform: translateY(5px);
        transform-origin: 2px -3px;
      }
    }
    &.active{
      >span{
        background-color: transparent;
          &::before{
          transform: translateY(-7px) rotate(45deg);
          transform-origin: 3px 4px;
        }
        &::after{
          transform: translateY(5px)  rotate(-45deg);
          transform-origin: 2px -3px;
        }
      }
    }
  }



  .hidden{
    opacity:0%;
  }

  >.buttonSimple{
    width: 64px;
  }

  .menuBtn,
  .homeBtn{
    svg{
      stroke:$white;
    }
  }

}