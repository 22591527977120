.avatar-img img {
    max-width: 32px !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
}

.topMenuAdmin {
    grid-column: 1/4;
    grid-row: 1/2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    z-index: 1;

    .logoContainer {
        display: flex;
        width: 100%;
        justify-content: start;
        align-self: center;
        cursor: pointer;

        @media (max-width: 767px) {
            flex: 1;
        }
    }

    .left {
        display: flex;
        width: auto;
        .hamburger {
            margin: 0rem;
            height: 5rem;
            width: 5rem;
            flex: 0 0 5rem;
            white-space: break-spaces;
            border: 0;
            outline: none;
            position: relative;
            transition: all 0.25s ease-out;
            cursor: pointer;
            &:active {
                background-color: #cdcdcd;
            }
            > span {
                background-color: #939393;
                width: 22px;
                height: 2px;
                display: block;
                position: absolute;
                pointer-events: none;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.25s ease-out;
                &::before,
                &::after {
                    content: "";
                    background-color: #939393;
                    width: 22px;
                    height: 2px;
                    display: block;
                    border-radius: 1px;
                    transition: all 0.25s ease-out;
                }
                &::before {
                    transform: translateY(-7px);
                    transform-origin: 3px 4px;
                }
                &::after {
                    transform: translateY(5px);
                    transform-origin: 2px -3px;
                }
            }
            &.active {
                > span {
                    background-color: transparent;
                    &::before {
                        transform: translateY(-7px) rotate(45deg);
                        transform-origin: 3px 4px;
                    }
                    &::after {
                        transform: translateY(5px) rotate(-45deg);
                        transform-origin: 2px -3px;
                    }
                }
            }
        }
    }

    .right {
        display: flex;

        @media (max-width: 767px) {
            display: none;
        }
    }


    .menuSearchAdmin {
        width: 40vw;
        background-color: #f3f3f3;
        border: none;
        padding: 0.75rem;
        color: #cccccc;

        &:focus {
            outline: none;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
}