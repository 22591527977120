
.custom-circle-avatar {
  font-family: Montserrat, serif;
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 45px;
  font-weight: bold;
  color: #fff;
}

.close {
  :hover {
    cursor: pointer;
  }
}

.button-customize {
  background-color: #4E560C !important;
  font-weight: bold !important;
  letter-spacing: .5px;

  &:hover {
    background-color: #6e7813 !important;
  }
}

.button-customize-outline {
  border-color: #4E560C !important;
  color: #4E560C !important;

  &:hover {
    border-color: #6e7813 !important;
    color: #6e7813 !important;

  }
}