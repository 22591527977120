.multistep{
    //TODO: clarify: do we need this background layer?
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 45.31%);
    min-height: 160px;
    padding: 1.5rem;
    padding-bottom: 3rem;
    align-items: flex-end;
    display: flex;
    
    .row-container{
        display: flex;
        flex: 1;
        justify-content: space-between;
        column-gap: .5rem;
        align-items: center;
        
        .step-container{
            display: flex;
            flex: 1;
            flex-direction: column;
            min-height: 2rem;
            justify-content: flex-end;
            .step-label{
                color: $black;
                margin-bottom: .5rem;
                @include ptype-4;
            }
            .bar{
                display: flex;
                background-color: $light-base1;
                height: .25rem;
                @include border-radius(4);
                
            }
            .done{
                background-color: $maib-primary;
            }
            .selected{
                height: .5rem;
                background-color: $blue2;
            }
        }
        .button-container{
            margin-top: 1.5rem;
        }
    }
}
