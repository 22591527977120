.checkbox-k3{
    display: inline-flex;
    cursor: pointer;
    &.checkbox-disabled{
        cursor: default;
        color: $detailstype;
        .checkbox-icon{
            fill: $dark-base-4;
        }
        .checkbox-icon{
            @include disabled-color-modifier($attribute: 'fill')
        }
    }
    .checkbox-icon{
        margin-right: .25rem;
        display: flex;
        align-items: center;
        fill: $dark-base-2;
        @include color-modifier($attribute: 'fill');
    }
}
