@import 'style/_media.scss';

.small-slider{
  .item{
    &:hover,
    &:focus{
      outline:none;
      border:0;
      box-shadow:none;
    }
    >img{
      border:0;
      outline:none;
      user-select: none;
      object-fit: cover;
      width: 100%;
      height: 486px;
      @include max-S{
        height:50vw;
        //object-fit: unset;
      }
      &:hover,
      &:focus{
        outline:none;
        border:0;
        box-shadow:none;
      }
    }
  }
  .slick{
    &-dots{
      position: absolute;
      z-index:10;
      bottom:0;
      width:100%;
      text-align: center;
      padding:0;
      margin:0 auto 15px;
      list-style: none;
      >li{
        display: inline-block;
        overflow: hidden;
        background: $white;
        border: 1px solid $text-light-01;
        box-sizing: border-box;
        border-radius: 4px;
        width:16px;
        height:16px;
        margin:0 4px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &.slick-active{
          background: $button-focus;
          border: 1px solid $white;
        }
        >button{
          opacity: 0;
          width:16px;
          height:16px;
          cursor: pointer;
        }
      }
    }
  }
}