.box-card-dash {
    display: flex;
    flex-direction: column;
    flex-basis: 400px;
    padding: 24px;
    color: $text-light-01;
    background-color: $white;
    .title {
      flex:0 0;
    }
    .text {
      width: 50%;
      display: flex;
      @include ptype-5-strong;
      flex: 1;
    }

    .unit {
      margin-top: auto;
    }

    &.restante {
      border: 1px solid $alert-red;
      background-color: #ffeaee !important;
      color: $alert-red;
      grid-area: restante;
      .text {
        background-color: #ffeaee;
        color: $alert-red;
      }
      .price {
        margin-top: auto;
        @include etype-5;
        display: flex;
        background-color: #ffeaee;
        color: $alert-red;
      }
    }
    &.total {
      background-color: $button-prime;
      grid-area: total;
      .text {
        background-color: $button-prime;
        color: $white;
      }
      .price {
        margin-top: auto;
        @include etype-5;
        color: $white;
        display: flex;
        background-color: $button-prime;
      }
      .title {
        background-color: $button-prime;
      }
    }

    &.achitat {
      background-color: $white;
      border: none;
    }
    &.recent {
      background: $white;
      border: 1px solid $green-40;
      box-sizing: border-box;
    }

    &.next {
      background: $white;
      border: 1px solid $purple-60;
      box-sizing: border-box;
    }

    .red-text {
      color: $alert-red;
      @include etype-5;
    }
    .purple-text {
      color: $purple-60;
      @include etype-5;
    }
    .black-text {
      color: $black;
      @include etype-5;
    }
    .green-text {
      color: $green-40;
      @include etype-5;
    }

    
  }

  .cards-wrapper {
    &:empty{
      display: none;
    }
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    min-height: 350px;
    // justify-content: space-between;
    .card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 50%;
    }
  }