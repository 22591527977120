@import "../colors";

@mixin tag-color-modifier($attribute: 'color') {
    @each $name, $hex in $allColors {
      &-#{$name} {
        //TODO: we need an exact color difference formula
        // #{$attribute}: saturate( adjust-hue( lighten($hex, 50%), 24%), 24%)
        #{$attribute}: $hex
      }
    }
  }

  //Defaults:
  $fontColor: $darkbkg;
  $iconColor: $light-base2;
  $iconColorDisabled: $light-base;
  $backgroundColor: $light-base1;

.tag-wrapper{
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    height: 2rem;
    padding: 0 .5rem;
    background-color: $backgroundColor;
    border-radius: .25rem;
    @include ptype-4;
    
    &.tag-bg{
        @include tag-color-modifier($attribute: 'background-color')
    }
    &.tiny{
        height: 21px;
        @include ptype-3;
    }
    
    .bold-font{
        font-weight: bold;
    }

    .tag-color{
        color: $fontColor;
        @include tag-color-modifier;
    }
    .tag-icon{
        display: flex;
        align-items: center;
        margin-left: .5rem;
        cursor: pointer;
        &.tag-fill{
            fill: $iconColor;
            stroke: $iconColor;
            @include tag-color-modifier($attribute: 'fill')
        }
        &.tag-disabled{
            fill: $light-base2;
            cursor: default;
            // @include tag-color-modifier($attribute: 'fill')
        }
    }
    &.full-size{
        width: 100%;
        height: 100%;
        margin:0;
        border-radius: 0;
        justify-content: center;
        align-items: center;
    }
}
