.sys-confirm-action {
    .modal-body {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        >span {
            margin-top: 32px;
            margin-bottom: 40px;
        }
    }

    .conf-buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (min-width: 768px) { 
            flex-direction: row;
         }
    }
}