.order-view-wrapper {
    $m767:767px;
    @media (max-width:$m767){
        padding:0 1.5rem;
    }
    
    .page-section{
      display: flex;
      // columns: 2;
      // column-gap: 1.5rem;
      gap: 1.5rem;
      margin-bottom: 2.5rem;
      
      @media (max-width:$m767){
          columns:unset;
          margin-bottom: 1.5rem;
      }
      
      & > div{
        break-inside: avoid;
        display: flex;
        flex: auto;
        flex-direction: column;
        gap: 1.5rem;
        
        &.right-box{
          max-width: 420px;
        }

        @media (max-width:$m767){
            grid-gap: 0;
            gap: 0;
            margin-bottom: 0;
            > div{
                grid-gap:0;
                gap: 0;
                margin-bottom: 1.5rem;
            }
        }
      }
  }

    .payment-status-box{
      stroke: $white;
      fill: $white;
      color: $white;
      padding: .5rem;
      padding-right: .75rem;

      .title{
        padding-right: .25rem;
        padding-left: .75rem;
        display: inline-flex;
        align-items: center;
        @include ptype-5-strong;
      }
      .details{
        margin: .25rem 0;
        display: inline-flex;
        align-items: center;
        @include ptype-5;
        
      }
      
      &.with-border{
        padding-right: .5rem;
        
        .title{
          padding: 0 .75rem;
        }
        .details{
          padding: 0 .75rem;
          border-left: 1px solid $white;
        }
      }
    }

    .summary-subtitle{
        display: flex;
        gap: 1.5rem;
    }

    .order-summary-grid{
      display: grid;
      grid-template-columns: repeat(4, minmax( 10rem, 1fr));
      margin-top: 1.5rem;
      margin-bottom: .5rem;
      gap: 2rem;
      @include max-LG{
        grid-template-columns: repeat(3, minmax( 10rem, 1fr));
        .emptyDiv{
          display: none;
        }
      }
      @include max-MD{
        grid-template-columns: repeat(2, minmax( 10rem, 1fr));
      }
      @include max-S{
        grid-template-columns: repeat(1, minmax( 10rem, 1fr));
      }
      
    }

    .item-box {
      background-color: $white;
      border: 1px solid $grey-40;
      padding: 1rem;
      box-sizing: border-box;

      &.border-blue{
        border-color: $text-link;
      }

      .title{
        margin: 0.25rem 0;
      }
      .detail-box{
        padding: .5rem;
        max-width: 350px;
      }
      .value-box{
        background-color: $grey-10;
        padding: .25rem 1rem;
        width: 200px;

      }
    }
    
    .infoCard{
      display: flex;
      border: 1px solid $grey-40;
      height: 4rem;
      flex: 0 1 20rem;
      align-items: center;
      padding-left: 0.5rem;
      img{
        margin-right: 0.75rem;
      }
      &>div{
        margin-right: 0.5rem;
      }
    }

    .action-icon{
        border: none;
        justify-content: flex-end;
        background: inherit;
        &:hover {
            border: none;
            stroke-width: 1.5;
        }
    }
    
    .credit-level-bar{
      display: flex;
      position: relative;
      
      div{
        height: 4px;
        position: absolute;
      }
      .bar-base{
        background-color: $blue-60;
        width: 100%;
      }
      .bar-current-level{
        background-color: $green-40;
      }
      .bar-estimated-level{
        background-color: $button-prime;
      }
      .custom-width{
        width: var(--width, 100);

      }
    }

    .white-card-no-title-margin{
      &>div:first-child{
        margin-bottom: .25rem;
      }
    }
    .icon-nostroke{
        stroke: none;
    }
    .center-text{
      display: inline-flex;
      align-items: center;
    }

}
