
.hamburger {
  margin: 0;
  height: 5rem;
  width: 5rem;
  flex: 0 0 5rem;
  white-space: break-spaces;
  background-color: var(--top-menu-bg-gray);
  border: 0;
  outline: none;
  position: relative;
  transition: all 0.25s ease-out;
  cursor: pointer;
  &:active {
    background-color: #cdcdcd;
  }
  > span {
    background-color: #939393;
    width: 22px;
    height: 2px;
    display: block;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease-out;
    &::before,
    &::after {
      content: "";
      background-color: #939393;
      width: 22px;
      height: 2px;
      display: block;
      border-radius: 1px;
      transition: all 0.25s ease-out;
    }
    &::before {
      transform: translateY(-7px);
      transform-origin: 3px 4px;
    }
    &::after {
      transform: translateY(5px);
      transform-origin: 2px -3px;
    }
  }
  &.active {
    > span {
      background-color: transparent;
      &::before {
        transform: translateY(-7px) rotate(45deg);
        transform-origin: 3px 4px;
      }
      &::after {
        transform: translateY(5px) rotate(-45deg);
        transform-origin: 2px -3px;
      }
    }
  }
}

.citizen-colors {
  --top-menu-bg-primary: #242525;
  --top-menu-bg-secondary: #565151;
  --top-menu-text-primary: #CAC5C4;
  --top-menu-text-secondary: #CAC5C4;
  --top-menu-bg-gray: #242525;
}

.admin-colors {
  --top-menu-bg-primary: #fff;
  --top-menu-bg-secondary: #F3F3F3;
  --top-menu-bg-gray: #E6E6E6;
  --top-menu-text-primary: #000;
  --top-menu-text-secondary: #CCCCCC;
}

.top-menu {
  background: var(--top-menu-bg-primary);



  @media (max-width: 767px) {
    justify-content: space-between;
    img {
      order: -1;
    }
  }

  .hide-on-small-screen {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.gray-box {
  background: var(--top-menu-bg-gray);
}


.top-menu-search {
  background: var(--top-menu-bg-secondary);
  border: none;
  color: var(--top-menu-text-secondary);
  padding: 0 0.75rem;
  height: 48px;
  border-radius: 2px;
  &:focus { outline: none; }
}

.nav-item {
  color: var(--top-menu-text-primary);
  background: var(--top-menu-bg-secondary);
  border-radius: 2px;
  height: 48px;
}

.profile-dropdown {
  right: 0;
  z-index: 11;
  border: 1px solid #666666;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: max-content;
}

.avatar-txt {
  width: 32px;
  height: 32px;
  background-color: #86C2FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}