.LeftMenu {
  background: #fdfdfc;
}

.dropdown-menu {
  position: fixed;
  top: 80px;
  left: 0;
  background: #fff;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateX(-100%);

  transition: all .15s ease-out;

  @media (max-width: 767px) {
    transform: translateY(calc(-100% - 80px));
    width: 100vw;
    height: auto;
    max-height: calc(100vh - 80px);

  }

  &.dropdown-menu-opened {
    transform: translate(0);
    //overflow: hidden;
  }
}