

.custom-select-flex {
  display: flex;
  width: 250px;
  align-items: center;
  color: $text-light-01;
  >div:first-child{
    margin-right: 8px;
    @include ptype-4-bold;
  }
}
.custom-select-small{
  // border: 4px solid purple;
  @include ptype-2;
  color: $text-light-01;
  flex: 1;
  .crs__control{
    background-color: $grey-10;
    box-shadow: none;
    border:none;
    border-radius: 0;
    // height: 20px;
    min-height:auto;
  }
  .crs__indicator{
    // color: red;
    padding: 0;
    padding-right: 4px;
  }
  .crs{
    &__menu{
      border-radius: 0;
      &-list{
        // border: 1px solid red;
        padding: 0;
      }
    }
    &__option{
      background-color: $grey-10;
      padding: 6px 12px;
      &:hover{
        background-color: $grey-40;
      }
      &--is-focused{
        background-color: $grey-50;

      }
      &--is-selected{
        color: $text-light-01;
        background-color: $text-light-03;
        &:hover{
          background-color: $text-light-03;
        }
      }
    }
  }
}
