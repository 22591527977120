%redSmallCard { //old version. please use small-color-tag
  // box-sizing: border-box;
    background-color: $button-focus;
    @include ptype-3-strong;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 1px 0.5rem;
}

@mixin small-color-tag ($bg-color, $color:$white){
  background-color: $bg-color;
  @include ptype-3-strong;
  line-height: 1rem;
  color: $color;
  border-radius: 4px;
  padding: 3px 0.5rem 2px 0.5rem;
  white-space: nowrap;
  align-self: center;
}

.small-red-tag{
  @include small-color-tag($button-focus)
}

.small-green-tag{
  @include small-color-tag($green-40)
}

.small-purple-tag{
  @include small-color-tag($purple-60)
}

.redSmallCard{
  @extend %redSmallCard;
}

%badgeBackgroundColor {
    background-color: $yellow-60;
  
    &.small {
      background-color: $magenta-60;
    }
    &.reseller {
      background-color: $teal-60;
    }
    &.large {
      background-color: $purple-60;
    }
  }

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

.text-one-line{
  text-overflow: clip;
  white-space: nowrap;
}