.page-tab-bar{
    background-color: $light-base2;
    height: 5rem;
    display: flex;
    .tabs{
        display: flex;
        gap: 2px;
        align-items: flex-end;
        padding: 1rem 1.5rem;
        padding-bottom: 0;

        .tab{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0.75rem;
            padding-left: 1rem;
            cursor: pointer;
            background-color: $whitebkg;
            border: 1px solid $light-base1;
            box-sizing: border-box;
            height: 3rem;
            @include ptype-4;
        }
        .selected{
            font-weight: bold;
            height: 4rem;
            background-color: $bluebkg;
        }
        .icon-container{
            margin-right: .5rem;
        }
        .close-btn-container{
            margin-left: .5rem;
            display: flex;
        }
        .close-icon{
            fill: $light-base2;
            display: flex;
            align-items: center;
        }
    }
}