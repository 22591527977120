
$light-silver:#8D8D8D;


.dvcs-reorder-column{
  background-color: $white;
  width: 100%;
  max-width: 360px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding:24px;
  margin-bottom: 2rem;
  .title{
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 22px;
  }
}

.dvcs-draggable-column{
  background: #EDF5FF;
  background-position: center 0;
  width: 100%;
  max-width: 360px;
  height: auto;
  margin-bottom: 1.5rem;
  .drag-item{
    background-color: $white;
    display: flex;
    border:solid 1px $white;
    width: 100%;
    max-width: 360px;
    height: 40px;
    border-radius: 0;
    padding:0 0.5rem;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
    display: flex;
    flex-direction: row;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    &:hover,&:focus{
      outline: none;
      box-shadow: none;
      border:1px solid #005CB9;
    }
    .left-items{
      display: flex;
      align-items: center;
    }
    .title{
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      display: block;
      height: 40px;
      padding: 10px 0;
      padding-right: 1rem;
      box-sizing: border-box;
      margin: 0;
    }
    &.dragging{
      border: 1px solid #0043CE;
      .drag-handler-premium,
      .check-outline-premium{
        opacity: 0;
      }
    }
    &.disabled{
      pointer-events: none;
      .title{
        color:#C6C6C6;
      }
    }
  }
}

.drag-handler-premium{
  user-select: none;
  width: 6px;
  height: 22px;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
}
.check-outline-premium{
  cursor: pointer;
}