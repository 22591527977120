.progressCard {
    .credit-limit {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @extend %ptype-5;
        >div{
          &:not(.credit-limit-value){
            margin-top: 24px;
          }
        }
    
        .credit-limit-value {
          display: flex;
          align-items:center;
          width:100%;
          max-width:284px;
          margin-top: 12px;
          padding:10px 20px;
          min-height: 40px;
          border: 1px solid $grey-40;
          text-align: left;
          box-sizing: border-box;
          @include max-S{
            max-width:100%;
          }
        }
        .limit-child {
          width: 320px;
          margin-right: 5%;
          @extend %ptype-5;
          @include max-S{
            margin-right: 0;
            width: 100%;
          }
        }
      }

      .retinut {
        margin-top: 20px;
      }
      .elibera-limita {
        display: flex;
        align-items: center;
        margin-right: 16px;
        padding: 20px;
        border: 1px solid $grey-40;
        .elibera-limita-text {
          @extend %ptype-5;
        }
      }

      .progress-bar {
        background-color: $white;
        padding: 16px;
        margin-bottom: 24px;
    
        margin-top: 24px;
        .blue-bar {
          margin-top: 24px;
          background-color: $white;
        }
        .purple-bar {
          margin-top: 50px;
        }
      }

      .sold-amount {
        display: flex;
        padding: 20px 0;
        .amount {
          margin-right: 30px;
          width: 320px;
          .amount-cube {
            border: 1px solid $grey-40;
            padding: 10px 20px;
            display: flex;
            align-items: center;
          }
        }
        .cube {
          width: 16px;
          height: 16px;
    
          &.purple {
            background-color: blueviolet;
          }
          &.ramas {
            background-color: $blue-50;
          }
        }
      }

    .progressbar-value {
        position: absolute;
        z-index: 15;
        color: $white;
        top: 50%;
        font-size: 16px;
        transform: translateY(-50%);
        padding-left: 20px;
    }
}