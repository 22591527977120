.tab-bar{
    .tabs{
        display: flex;
        gap: 2px;
        margin-bottom: 1.5rem;
        .tab{
            flex: 1;
            padding: .75rem 0;
            padding-left: 1rem;
            border: 2px solid transparent;
            border-bottom: 2px solid $light-base1;
            cursor: pointer;
            &:hover{
                color: $darkbkg; //TODO: dark background or primary dark black?;
                font-weight: bold;
                border-bottom-color: $blue1;
            }
            &:focus{
                border: 2px solid #0043CE; //TODO: outline or border?;
                font-weight: bold;
                outline: none;
            }
        }
        .selected{
            font-weight: bold;
        }
    }
}
