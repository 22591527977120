$light-invoice-grey: #8d8d8d;
.invoiceViewGrid {
  display: flex;
  // grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  gap: 1.5rem;
}
.invoice-wraper {
  .heading-2 {
    @include ptype-3;
    color: $button-prime;
    margin: 0 0 1rem;
  }
  @import "facturi";
}
.content-line {
  background-color: $white;
  margin-bottom: 0.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  > div {
    padding: 1.5rem 0 1.5rem 1rem;
    display: flex;
    flex-grow: 1;
    min-height: 88px;
    box-sizing: border-box;
    &.column {
      flex-direction: column;
    }
  }
  .delivered {
    display: flex;
    flex-direction: row;
  }
  .termen,
  .imageholder,
  .status,
  .problems {
    display: flex;
    align-items: center;
  }
  .column {
    justify-content: center;
    align-items: flex-start;
    > .wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .btn {
    &.btn {
      &-grey,
      &-green,
      &-transparent {
        text-transform: uppercase;
        border: 0;
        outline: none;
        height: 36px;
        padding: 0.25rem 0.75rem;
        @extend %ptype-4-bold;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $text-light-01;
      }
      &-grey {
        background-color: $grey-10;
      }
      &-green {
        background-color: #dbfee5;
        color: $green-40;
      }
      &-transparent {
        background-color: transparent;
        @extend %ptype-4-price;
        .ico {
          width: 16px;
          height: 16px;
          display: inline-flex;
          margin-right: 0.5rem;
          background: transparent
            url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAZlBMVEUAAAD/gCD/gDD/gCj/gCX/hSr/gyj/gyz/hCr/gy3/gyv/gir/gyn/gyz/gin/gir/giz/gSz/gyr/gyz/giv/gSr/gyz/gyr/gyv/gyv/gyr/gyv/gyv/gyr/gyv/gyv/gyz/gytR+C05AAAAIXRSTlMAEBAgMDBAQE9QX2Bvb3B/f4CAgI+QkJ+foK+/z9/f7+8MrfUHAAAAi0lEQVR42kWOBRbCMBBEh069OHVbcv9Lsht5/EoyEoHC4XRub4nI7bs2LLrjLIJ+ShUmH/EOnerAuNt/mYNihkxKnex10OcdGAaduRy8qn7rvLO2EIX0piObVgv5a7w2/V1iSP1lxwORzs2A9ZNzLo5QeJxza4UDW7xUM0xWaFGFSqATkrtVIpsz5ActLggwxPu3xQAAAABJRU5ErkJggg==")
            0 0 no-repeat;
          &.ico-red {
            background: transparent
              url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAdVBMVEUAAADPECDPICDfICDXGCjXICjfICjaGyXaICXaICrXHCjYHSrZHSbZHijcHijXHSjaHSjaHifaHinYHifbHijZHijaHifYHifaHifaHSjaHijZHSjZHijZHSfaHSjaHyjaHifZHijaHijaHifaHinbHinaHighIAHnAAAAJnRSTlMAEBAQICAgMDAwQE9QX19gYG9vcH+Aj5CQn5+goK+/v8/f3+/v77brfqYAAACLSURBVHjaNYoFAkMxCMXY9t3dXbj/Ecer5EsbCAlOfzIfmUOG6l0TN8zPy9fePJG+tLeaOCyuGQ7850mb86Hfi90Ra79qSXq5cUBOKd7KPUf9uhS+HdywSRq+cEO7y+9rlo78fmdDhoJHIvR2ci2MhNzzGjMEJ22SgKSfEKQU6URT3J7nHUgMG4PnD/LmCazsgdgXAAAAAElFTkSuQmCC")
              0 0 no-repeat;
          }
        }
      }
    }
  }
  .date {
    @extend %ptype-4;
    color: $light-invoice-grey;
    margin-top: 0.25rem;
  }
  .delivered {
    @extend %ptype-5-strong;
    color: $text-light-01;
  }
  .truncate {
    @extend %ptype-4;
    color: $light-invoice-grey;
  }
}

.btn {
  &.btn {
    &-grey,
    &-green,
    &-transparent {
      text-transform: uppercase;
      border: 0;
      outline: none;
      height: 36px;
      // padding:0.25rem 0.75rem;
      @extend %ptype-4-bold;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $text-light-01;
    }
    &-grey {
      background-color: $grey-10;
      padding: 0.25rem 0.75rem;
    }
    &-green {
      background-color: #dbfee5;
      color: $green-40;
      padding: 0.25rem 0.75rem;
    }
    &-transparent {
      background-color: transparent;
      @extend %ptype-4-price;
      .ico {
        width: 16px;
        height: 16px;
        display: inline-flex;
        margin-right: 0.5rem;
        background: transparent
          url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAZlBMVEUAAAD/gCD/gDD/gCj/gCX/hSr/gyj/gyz/hCr/gy3/gyv/gir/gyn/gyz/gin/gir/giz/gSz/gyr/gyz/giv/gSr/gyz/gyr/gyv/gyv/gyr/gyv/gyv/gyr/gyv/gyv/gyz/gytR+C05AAAAIXRSTlMAEBAgMDBAQE9QX2Bvb3B/f4CAgI+QkJ+foK+/z9/f7+8MrfUHAAAAi0lEQVR42kWOBRbCMBBEh069OHVbcv9Lsht5/EoyEoHC4XRub4nI7bs2LLrjLIJ+ShUmH/EOnerAuNt/mYNihkxKnex10OcdGAaduRy8qn7rvLO2EIX0piObVgv5a7w2/V1iSP1lxwORzs2A9ZNzLo5QeJxza4UDW7xUM0xWaFGFSqATkrtVIpsz5ActLggwxPu3xQAAAABJRU5ErkJggg==")
          0 0 no-repeat;
        &.ico-red {
          background: transparent
            url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAdVBMVEUAAADPECDPICDfICDXGCjXICjfICjaGyXaICXaICrXHCjYHSrZHSbZHijcHijXHSjaHSjaHifaHinYHifbHijZHijaHifYHifaHifaHSjaHijZHSjZHijZHSfaHSjaHyjaHifZHijaHijaHifaHinbHinaHighIAHnAAAAJnRSTlMAEBAQICAgMDAwQE9QX19gYG9vcH+Aj5CQn5+goK+/v8/f3+/v77brfqYAAACLSURBVHjaNYoFAkMxCMXY9t3dXbj/Ecer5EsbCAlOfzIfmUOG6l0TN8zPy9fePJG+tLeaOCyuGQ7850mb86Hfi90Ra79qSXq5cUBOKd7KPUf9uhS+HdywSRq+cEO7y+9rlo78fmdDhoJHIvR2ci2MhNzzGjMEJ22SgKSfEKQU6URT3J7nHUgMG4PnD/LmCazsgdgXAAAAAElFTkSuQmCC")
            0 0 no-repeat;
        }
      }
    }
  }
}
.date {
  @extend %ptype-4;
  color: $light-invoice-grey;
  margin-top: 0.25rem;
}
.delivered {
  @extend %ptype-5-strong;
  color: $text-light-01;
}
.truncate {
  @extend %ptype-4;
  color: $light-invoice-grey;
}

.teable-header {
  @extend %ptype-4-bold;
  color: $text-light-02;
  fill: $text-light-03;
  // text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: $white;
  padding-bottom: 8px;
  &.moreActions {
    justify-content: center;
  }
  &.greenButton {
    margin-left: 0px;
  }
  .sort-part {
    > div {
      height: 16px;
      padding: 0 0.75rem;
      cursor: pointer;
    }
    .up > svg {
      transform: translateY(1px);
    }
    .down > svg {
      transform: translateY(-5px);
    }
  }
  .active {
    fill: $text-light-01;
  }
}

@mixin circle($size) {
  width: $size;
  flex: 0 0 $size;
  height: $size;
  border-radius: 50%;
  line-height: $size;
  text-align: center;
}
.table-default-cell {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  // grid-template-areas:
  //   "avatar title"
  //   "avatar subtitle"
  // ;
  &.center {
    justify-content: center;
  }

  .imgwrap {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    background-color: $blue-60;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 0.5rem;
    img {
      max-width: 32px;
      max-height: 32px;
    }
    &.round {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        min-height: 42px;
        min-height: 42px;
        max-width: 60px;
        max-height: 60px;
      }
    }
  }
  .avatar {
    color: white;
    @include circle(40px);
    margin-right: 0.5rem;
    @include ptype0();
  }
  > div {
    > div + div {
      margin-top: 0.25rem;
    }
  }
  .tag-keeper {
    display: flex;
    > div + div {
      margin-left: 0.25rem;
    }
  }
  .title {
    @extend %ptype-4-bold;
    &.upercase {
      text-transform: uppercase;
    }
    &.tempSkinnyTitle {
      font-weight: normal;
    }
    &.twoLines {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
  .clickable {
    cursor: pointer;
    &:hover {
      color: lighten($text-light-01, 20%);
    }
    &:active {
      color: darken($text-light-01, 10%);
    }
  }
  .subtitle {
    @extend %ptype-4;
    color: $text-light-02;
    // margin-top:0.25rem; moved to D1 because for items with only one row it applies... and it should not
  }
  .paragraph {
    @extend %ptype-4;
    color: $text-light-02;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 200px;
  }
}

$row-height: 64px;
.magic-table-wrapper {
  display: grid;
  //width: max-content;
  grid-template-columns: repeat(var(--repeat-collums), auto);
  //grid-template-columns: repeat(var(--repeat-collums), minmax(0, 1fr));
  //white-space: pre-wrap;


  //grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));


  &.with-select {
    grid-template-columns: 40px repeat(var(--repeat-collums), auto);

    &-more-actions {
      grid-template-columns: 40px repeat(var(--repeat-collums), auto) 64px;
    }
  }
  &.with-more-actions {
    grid-template-columns: repeat(var(--repeat-collums), auto) 64px;
  }
  &.with-green-button {
    grid-template-columns: repeat(var(--repeat-collums), auto) 200px;
  }
  .header {
    display: contents;
    > div + div {
      padding-left: 16px;
    }
    > div {
      border-bottom: 1px solid $mt-intercell-border;
    }
  }
  .header > div:first-child,
  .row > div:first-child {
    padding-left: 8px !important;
  }
  .row {
    > div + div {
      padding-left: 16px;
    }
    display: contents;
    > div {
      background-color: $white;
      // height: $row-height;
      display: flex;
      align-items: center;
      transition: all 0.2s ease-out;
    }
    > div {
      border-bottom: 1px solid $mt-intercell-border;
    }
  }
  .row:hover {
    > div {
      background-color: $mt-row-hover;
      --magic-table-bg-on-hover: #ecf5ff;
    }
  }
  .selected > div {
    background-color: $mt-row-select;
  }
  // .selected:hover> div{
  //   // background-color: $grey-10;

  // }
}

.company-page {
  .tag-wrapper {
    &.tag-inactive {
      .icon-container {
        display: none;
      }
    }
  }
}

.responive-table {
  display: flex;
  flex-direction: column;
}

.responsive-card {
  background: white;
  margin-top: 16px;

  .plus-padding {
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  .status-text {
    font-weight: 700;
    font-size: 12px;
    color: white;
    padding: 8px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.align-end {
      justify-content: flex-end;
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      color: #161616;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
