.buttons svg {
    fill: #d7b46a !important;
}
.lang-box {
    margin-right: 15px;
    font-size: 14px;
    min-width: 48px;
    height: 48px;
    color: #CAC5C4;
    background-color: #565151;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.topMenu {
    grid-column: 1/4;
    grid-row: 1/2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #242525;

    .logoContainer {
        display: flex;
        width: 100%;
        justify-content: start;
        align-self: center;

        @media (max-width: 767px) {
            flex: 1;
        }
    }

    .left {
        display: flex;
        width: auto;
        .hamburger {
            margin: 0rem;
            height: 5rem;
            width: 5rem;
            flex: 0 0 5rem;
            white-space: break-spaces;
            background-color: #242525;
            border: 0;
            outline: none;
            position: relative;
            transition: all 0.25s ease-out;
            cursor: pointer;
            &:active {
                background-color: #cdcdcd;
            }
            > span {
                background-color: #939393;
                width: 22px;
                height: 2px;
                display: block;
                position: absolute;
                pointer-events: none;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.25s ease-out;
                &::before,
                &::after {
                    content: "";
                    background-color: #939393;
                    width: 22px;
                    height: 2px;
                    display: block;
                    border-radius: 1px;
                    transition: all 0.25s ease-out;
                }
                &::before {
                    transform: translateY(-7px);
                    transform-origin: 3px 4px;
                }
                &::after {
                    transform: translateY(5px);
                    transform-origin: 2px -3px;
                }
            }
            &.active {
                > span {
                    background-color: transparent;
                    &::before {
                        transform: translateY(-7px) rotate(45deg);
                        transform-origin: 3px 4px;
                    }
                    &::after {
                        transform: translateY(5px) rotate(-45deg);
                        transform-origin: 2px -3px;
                    }
                }
            }
        }
    }

    .right {
        display: flex;

        @media (max-width: 767px) {
            display: none;
        }
    }

    
}
.search-menu-left {
    margin-right: 15px;
    justify-content: flex-end !important;
}
.menuSearchCitizen {
    height: 48px;
    max-height: 48px;
    width: 9vw;
    background-color: #565151;
    border: none;
    padding-left: 0.75rem;
    color: #cccccc;
    &:focus {
        outline: none;
    }
}

.inactive {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
    }
}