
$switchHeight: 20px;
$switchWidth: calc(2 * #{$switchHeight});

.switch-container-old{
  height: calc(#{$switchHeight} - 6px);
  width: $switchWidth;
  background-color: grey;
  border-radius: calc(0.5 * #{$switchHeight});
  padding: 3px;
  position: relative;
  .switch {
    position: absolute;
    height: calc(#{$switchHeight} - 6px);
    width: calc(#{$switchHeight} - 6px);
    background-color: $white;
    border-radius: calc((#{$switchHeight} - 6px) / 2);
    left: calc(0.15 * #{$switchHeight});
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.active{
    background-color: $all-good;
    .switch{
      left: calc(#{$switchWidth} - #{$switchHeight} + 6px + 3px);
    }
  }
}