.logout-popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 1px solid #666666;
    background-color: #ffffff;
    align-self: flex-end;
    padding: 10px;
    z-index: 10;
}
