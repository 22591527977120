//Normal level order >
$level-colors: $all-good, $alert-danger, $alert-critical;

@for $i from 0 to length($level-colors) {
    .level-color-#{$i} {
        background-color: nth($level-colors, $i+1);
    }
}

.pgbar{
    flex: 1;
    .base{
        display: flex;
        background-color: $greybkg;
        @include border-radius();
        position: relative;

    }
    .level{
        height: 1.5rem;
        @include border-radius();
    }
    .title-container{
        color: $light-base4;
        margin-bottom: 1rem;
        @include ptype-2; 
    }
    .label-container{
        display: flex;
        justify-content: space-between;
        color: $darkesttype;
        @include ptype0;
    }
}
