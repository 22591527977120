@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap%27');

@mixin text-variants($variant) {
    @if $variant == "regular" {
        font-weight: 400;
        }
    @if $variant == "bold" {
        font-weight: bold;
    }
    @if $variant == "light" {
        font-weight: 300;
    }
    @if $variant == "italic" {
        font-style: italic;
    }
    @if $variant == "mono" {
        font-family: 'IBM Plex Mono';
    }
}


@mixin etype5-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    // line-height: 38px;
}
@mixin etype3-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    // line-height: 28px;
}

@mixin etype1-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    // line-height: 19px;
}
@mixin etype6-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    // line-height: 46px;
}
@mixin etype4-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    // line-height: 32px;
}

@mixin etype2-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    // line-height: 24px;
}

@mixin etype7-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    // line-height: 53%;
}

@mixin etype8-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    // line-height: 120%;
}

@mixin etype9-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    // line-height: 120%;
}

@mixin etype10-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    // line-height: 77%;
}

@mixin etype11-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    // line-height: 86px;
}
@mixin etype12-bold {
    font-family: "hero-new";
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    // line-height: 96px;
}

@mixin ptype-2($variants:"regular") {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    // line-height: 140%;
    @include text-variants($variants);
}

@mixin ptype-3($variants:"regular") {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 10px;
    // line-height: 140%;
    @include text-variants($variants);
}

@mixin ptype-1($variants:"regular") {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    // line-height: 140%;
    @include text-variants($variants);
}

@mixin ptype0 ($variants:"regular"){
    font-family: "Montserrat";
    font-style: normal;
    font-size: 16px;
    // line-height: 140%;
    @include text-variants($variants);
}



@mixin ptype2 ($variants:"regular"){
    font-family: "Montserrat";
    font-style: normal;
    font-size: 20px;
    // line-height: 140%;
    @include text-variants($variants);
}

@mixin ptype3 ($variants:"regular"){
    font-family: "Montserrat";
    font-style: normal;
    font-size: 24px;
    // line-height: 140%;
    @include text-variants($variants);
}

@mixin ptype4 ($variants:"regular"){
    font-family: "Montserrat";
    font-style: normal;
    font-size: 28px;
    // line-height: 140%;
    @include text-variants($variants);
}

@mixin ptype5 ($variants:"regular"){
    font-family: "Montserrat";
    font-style: normal;
    font-size: 32px;
    // line-height: 140%;
    @include text-variants($variants);
}



.etype5-bold{
    @include etype5-bold;
}
.etype3-bold{
    @include etype3-bold;
}
.etype1-bold{
    @include etype1-bold;
}
.etype6-bold{
    @include etype6-bold;
}
.etype4-bold{
    @include etype4-bold;
}
.etype2-bold{
    @include etype2-bold;
}
.etype7-bold{
    @include etype7-bold;
}
.etype8-bold{
    @include etype8-bold;
}
.etype9-bold{
    @include etype9-bold;
}
.etype10-bold{
    @include etype10-bold;
}
.etype11-bold{
    @include etype11-bold;
}
.etype12-bold{
    @include etype12-bold;
}
.ptype0{
    @include ptype0;
}

.ptype-1{
    @include ptype-1;
}
.ptype-2{
    @include ptype-2;
}
.ptype-3{
    @include ptype-3;
}
.ptype2{
    @include ptype2;
}
.ptype3{
    @include ptype3;
}
.ptype4{
    @include ptype4;
}
.ptype5{
    @include ptype5;
}


.ptype-1-bold{
    @include ptype-1("bold");
}
.ptype-2-bold{
    @include ptype-2("bold");
}
.ptype-3-bold{
    @include ptype-3("bold");
}
.ptype2-bold{
    @include ptype2("bold");
}
.ptype3-bold{
    @include ptype3("bold");
}
.ptype4-bold{
    @include ptype4("bold");
}
.ptype5-bold{
    @include ptype5("bold");
}


.ptype-1-italic{
    @include ptype-1("italic");
}
.ptype-2-italic{
    @include ptype-2("italic");
}
.ptype-3-italic{
    @include ptype-3("italic");
}
.ptype2-italic{
    @include ptype2("italic");
}
.ptype3-italic{
    @include ptype3("italic");
}
.ptype4-italic{
    @include ptype4("italic");
}
.ptype5-italic{
    @include ptype5("italic");
}


.ptype-1-light{
    @include ptype-1("light");
}
.ptype-2-light{
    @include ptype-2("light");
}
.ptype-3-light{
    @include ptype-3("light");
}
.ptype2-light{
    @include ptype2("light");
}
.ptype3-light{
    @include ptype3("light");
}
.ptype4-light{
    @include ptype4("light");
}
.ptype5-light{
    @include ptype5("light");
}

.ptype-1-mono{
    @include ptype-1("mono");
}
.ptype-2-mono{
    @include ptype-2("mono");
}
.ptype-3-mono{
    @include ptype-3("mono");
}
.ptype2-mono{
    @include ptype2("mono");
}
.ptype3-mono{
    @include ptype3("mono");
}
.ptype4-mono{
    @include ptype4("mono");
}
.ptype5-mono{
    @include ptype5("mono");
}
