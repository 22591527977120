.product-form-wrapper {
    //Layout
    .wrap-sections{
        display: column;
        columns: 2;
        gap: 1.5rem;
        margin-bottom: 2.5rem;
        @media (max-width:767px) {
            columns: 1;
        }
        & > div{
            break-inside: avoid;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
    .inline-sections{
        display:  flex; 
        align-items: flex-start;
        gap: 1.5rem;
        >div{
            flex: 1;
        }
    }
    // Detalii technice
    .technical-details-section{
        .technical-detail-row{
            margin-bottom: .25rem;
            display: flex;
            color: $text-light-03;
            @include ptype-4;

            
            .action-icon{
                border: none;
                stroke: $text-light-03;
                justify-content: flex-end;
                background: inherit;
                &:hover {
                border: none;
                stroke-width: 1.5;
                }
            }

            &.visible{
                color: $text-light-01;

                .action-icon{
                    stroke: $text-light-01;
                }
            }
        }

        
        .label-box{
            padding: 1rem;
            width: 50%;
            display: flex;
            align-items: center;
            // background-color: $gray-40; //TODO:SE: debug - not working
        }
        .value-box{
            padding: 1rem;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            .action-icon{
                right: 0;
            }
        }
    }

    .product-form-currency-box{
        border-left: 1px solid $selectionbkg;
        padding-left: .75rem;
        padding-right: .5rem;
    }

    .icon-nostroke{
        stroke: none;
    }

    .uppercase {
        text-transform: uppercase;
    }
}
