
.circle-avatars-cell{
  display: flex;
  .line{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .circle{
    width:40px;
    height:40px;
    border-radius: 50%;
    // margin-right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: $white;
    user-select: none;
    position: relative;
    font-size: 16px;
    img{
      max-width: 44px;
      max-height: 44px;
    }
    &.add{
      border:dashed 1px $inactive;
      overflow: visible;
      cursor: pointer;
      background-color: $white;
      &:hover{
        border:dashed 1.5px $text-light-02;
      }
      &:focus,
      &:active{
        // border:dashed 1px $text-light-02;
        outline: none;
        box-shadow: none;
      }
      svg{
        stroke:$inactive;
        opacity:1;
      }
      .icon{
        background-color: $inactive;
        position: absolute;
        right:0;
        bottom:0;
        z-index: 1;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        margin: 0 -2px -2px 0;
        transform-origin: center;
        &::before,
        &:after{
          content:'';
          position: absolute;
          top:50%;
          left:50%;
          background-color: $white;
          width: 7px;
          height: 1px;
        }
        &::before{
          transform: translate(-50%,-50%);
        }
        &::after{
          transform: translate(-50%,-50%) rotate(90deg);
        }
      }
    }
  }

  .circle:not(.add){
    &:nth-last-child(1){
      background-color: $purple-1;
    }
    &:nth-last-child(2){
      background-color: $cyan-1;
    }
    &:nth-last-child(3){
      background-color: $magenta-1;
    }
    &:nth-last-child(4){
      background-color: $red-1;
    }
    &:nth-last-child(5){
      background-color: $primary-1;
    }
    &:nth-last-child(6){
      background-color: $teal-1;
    }
    &.grey{
      background-color: $light-base4;
    }
    
  }
}