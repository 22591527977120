
@mixin color-modifier($attribute: 'color') {
  @each $name, $hex in $allColors {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}

@mixin svg-color-modifier() {
  @each $name, $hex in $allColors {
    &-#{$name} {
      stroke: $hex;
      fill: $hex;
    }
  }
}

@mixin color-button-modifier($attribute: 'color') {
  @each $name, $hex in $allColors {
    &-#{$name} {
      #{$attribute}: $hex;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover{
        #{$attribute}: lighten($hex, 20%);
      }
      &:focus{
        #{$attribute}: lighten($hex, 10%);
      }
      &:active{
        #{$attribute}: darken($hex, 10%);
      }
    }
  }
}

@mixin bg-button-modifier($attribute: 'color') {
  @each $name, $hex in $allColors {
    &-#{$name} {
      #{$attribute}: $hex;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover{
        @if $hex == #ECF5FF or $name == "grey-50" {
          #{$attribute}: lighten($hex, 2%);
        } @else {
          #{$attribute}: lighten($hex, 10%);

        }

      }
      &:focus{
        #{$attribute}: lighten($hex, 10%);
      }
      &:active{
        #{$attribute}: darken($hex, 10%);
      }
    }
  }
}


@mixin border-modifier($width: 1) {
  @each $name, $hex in $allColors {
    &-#{$name} {
      border: #{$width}px solid $hex;
    }
  }
}

@mixin disabled-color-modifier($attribute: 'color') {
  @each $name, $hex in $allColors {
    &-#{$name} {
      #{$attribute}: lighten($hex, 20%);
    }
  }
}

@mixin border-radius($value: 4){
  border-radius: #{$value}px;
}

@each $name, $hex in $allColors {
  .#{$name} {
    color: $hex;
  }
}

.bg{
  @include color-modifier($attribute: 'background-color');
}

.color{
  @include color-modifier($attribute: 'color');
}


.stroke{
  @include svg-color-modifier();
}

.fill{
  @include color-modifier($attribute: 'fill');
}

.btn-txt{
  @include color-button-modifier($attribute: 'color');
  text-decoration: none;
}

.btn-bg{
  @include bg-button-modifier($attribute: 'background-color');
}

// .stroke{
//   @include color-modifier($attribute: 'stroke');
// }

.border{
  @include border-modifier($width: 1);
}

.bold{
  font-weight: bold;
}

.flex{
  display: flex;
  &.alignCenter, .ac{
    align-items: center;
  }
  &.alignStart, .as{
    align-items:flex-start;
  }
  &.alignEnd, .ae{
    align-items:flex-end;
  }
  &.justifyCenter, .jc{
    justify-content:centerend;
  }
  &-sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.aifs{
    align-items: flex-start;
  }
  &.equalColl{
    width: 100%;
    flex: 1;
    >div{
      flex: 1;
    }
  }
  &.equalColl-col{
    width: 100%;
    >div{
      flex: 1;
    }
  }
  &.column {
    flex-direction: column;
  }
}

.uppercase{
  text-transform: uppercase;
}

.w100{
  width: 100%;
}

.fill-darkest{
  fill:  $text-light-01;
}
.no-wrap{
  white-space: nowrap;
}
.btn-stroke-text-light-01{
  stroke: $text-light-01;
  cursor: pointer;
  &:hover{
    stroke-width: 1.5;
  }
}

@mixin circle($size) {
  width: $size;
  height: $size;
  flex: 0 0 $size; //this one is for safety, maybe you are in a flex container (that will ignore one of width / height)
  border-radius: 50%;
  line-height: $size;
  text-align: center;
}

//THE CODE ABOVE WILL GENERATE THE CLASSES BELOW

// .bg-all-good{
//   background-color: $all-good;
// }

// .all-good{
//   color: $all-good;
// }
// .bg-grey-50{
//   background-color: $grey-50;
// }
// .bg-grey-40{
//   background-color: $grey-40;
// }
// .bg-grey-20{
//   background-color: $grey-20;
// }
// .bg-grey-10{
//   background-color: $grey-10;
// }
// .bg-grey-16{
//   background-color: $grey-16;
// }

// .bg-button-prime{
//   background-color: $button-prime;
// }
// .bg-green-40{
//   background-color: $green-40;
// }
// .bg-button-focus{
//   background-color: $button-focus;
// }

// .alert-red{
//   color: $alert-red;
// }

// .alert-danger{
//   color: $alert-danger;
// }

// .grey-50 {
//   color: $grey-50;
// }

// .grey-40 {
//   color: $grey-40;
// }

// .grey-20 {
//   color: $grey-20;
// }

// .grey-10 {
//   color: $grey-10;
// }

// .grey-16 {
//   color: $grey-16;
// }


// .white {
//   color: $white;
// }

// .black {
//   color: $black;
// }

// .red-10 {
//   color: $red-10;
// }

// .steel-grey {
//   color: $steel-grey;
// }

// .text-light-01 {
//   color: $text-light-01;
// }

// .text-light-02 {
//   color: $text-light-02;
// }

// .text-light-03 {
//   color: $text-light-03;
// }


// .button-prime {
//   color: $button-prime;
// }

// .hover-button-prime {
//   color: $hover-button-prime;
// }

// .click-button-prime {
//   color: $click-button-prime;
// }

// .button-secondary {
//   color: $button-secondary;
// }

// .hover-button-secondary {
//   color: $hover-button-secondary;
// }

// .click-button-secondary {
//   color: $click-button-secondary;
// }

// .button-focus {
//   color: $button-focus;
// }

// .hover-button-focus {
//   color: $hover-button-focus;
// }

// .click-button-focus {
//   color: $click-button-focus;
// }

// .placeholderBlue {
//   color: $placeholderBlue;
// }

// .green-40 {
//   color: $green-40;
// }

// .green2 {
//   color: $green2;
// }

// .magenta-60 {
//   color: $magenta-60;
// }

// .teal-60 {
//   color: $teal-60;
// }

// .yellow-60 {
//   color: $yellow-60;
// }

// .purple-60 {
//   color: $purple-60;
// }


.ptype-1 {
  @include ptype-1;
}

.ptype-2 {
  @include ptype-2;
}

.ptype-3 {
  @include ptype-3;
}

.ptype-4 {
  @include ptype-4;
}

.ptype-5 {
  @include ptype-5;
}

.ptype-6 {
  @include ptype-6;
}

.ptype-7 {
  @include ptype-7;
}

.ptype-8 {
  @include ptype-8;
}

.ptype-9 {
  @include ptype-9;
}

.etype-1 {
  @include etype-1;
}

.etype-2 {
  @include etype-2;
}

.etype-3 {
  @include etype-3;
}

.etype-4 {
  @include etype-4;
}

.etype-5 {
  @include etype-5;
}

.etype-6 {
  @include etype-6;
}

.etype-7 {
  @include etype-7;
}

.etype-8 {
  @include etype-8;
}

.ptype-3-strong {
  @include ptype-3-strong;
}

.ptype-2-reg {
  @include ptype-2-reg;
}

.ptype-4-bold {
  @include ptype-4-bold;
}

.ptype-4-price-strike {
  @include ptype-4-price-strike;
}

.ptype-5-strike {
  @include ptype-5-strike;
}

.ptype-4-price {
  @include ptype-4-price;
}

.ptype-1-specialdeal {
  @include ptype-1-specialdeal;
}

.ptype-5 {
  @include ptype-5;
}

.ptype-5-strong {
  @include ptype-5-strong;
}

@mixin pill($height, $border-width:0px) {
  width: fit-content;
  height: $height;
  line-height: calc(#{$height} - 2 * #{$border-width});
  border-radius: 4px;
  @if $height > 16px {
    padding: 0px 8px ;
  } @else {
    padding: 0px 4px ;
  }
}

@mixin circle($size) {
  width: $size;
  height: $size;
  flex: 0 0 $size; //this one is for safety, maybe you are in a flex container (that will ignore one of width / height)
  border-radius: 50%;
  line-height: $size;
  text-align: center;
}


@mixin text-border-color ($color) {
  color: $color;
  border-color: $color;
}
@mixin pill-common($border-height:0px){
  @include pill(20px, $border-height);
  font-family: hero-new;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  &.s-16{
    @include pill(16px, $border-height);
  }
  &.s-18{
    @include pill(18px, $border-height);
    font-family: hero-new;
    font-weight: 600;
    font-size: 8px;
  }
}

.w50 {
  width: 50%;
}
