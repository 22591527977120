.selected {
	background-color: #D7B46A !important;
}

.inactive {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
  }
}

.side-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.side-menu-label {
  padding-right: 2rem;
  font-weight: 800;
  width: max-content;
}
.side-menu-icon {
  width: 5rem;
  height: 5rem;
}
.no-scroll {
  overflow: hidden;
}
