.bcp{
  display: flex;
  ul{
    list-style: none;
    outline: none;
    padding:0;
    display: flex;
    justify-content: center;
    li{
      display: inline-flex;
      a{
        color:#005cb9;
      }
      &:last-child{
        a{
          color:#313131;
          pointer-events: none;
        }
      }
    }
    a{
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
      color:#005cb9;
      text-transform: capitalize;
    }
  }
}