.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.authNavbar {
    grid-column: 1/4;
    grid-row: 1/2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #242525;
    font-family: "Montserrat", "Open Sans", sans-serif;

    .logo {
        display: flex;
        width: 100%;
        justify-content: start;
        align-self: center;
    }

    .label {
        color: white;
        font-weight: 800;
        display: flex;
        align-items: center;
        margin-left: 30px;
        font-size: 24px;
        &:not(:last-child):after {
            content: "";
            background-color: #d7b46a;
            width: 0.5px;
            height: 30px;
            margin-left: 30px;
            display: inline-block;
        }
    }

    .left-items {
        display: flex;
        width: auto;
    }

    .right-items {
        display: flex;
    }
}

.lang-box {
    margin-right: 15px;
    font-size: 14px;
    min-width: 48px;
    height: 48px;
    color: #cac5c4;
    background-color: #565151;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
    user-select: none;
}

.backround-image {
    position: absolute;
    top: 0;
    left: 0;
    img {
        object-fit: cover;
        filter: blur(10px);
        transform: scale(1.2);
    }
}

.background-wrap {
    flex: 1;
    position: relative;
    overflow: hidden;
}

.centered-child {
    margin: 80px auto 0;
    max-width: 640px;
}
