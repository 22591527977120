.orderCard{
    width: 100%;
    max-width: 328px;
    $prodPicHeight: 32px;
    background-color: $white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @include max-S{
        // max-width:100%;
        margin: 0 auto;
    }
  .productsList{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;
      // width: 300px;
  .product{
      background-color: #ECF5FF;
      border-radius: 4px;
      height: $prodPicHeight;
      width: $prodPicHeight;
  }
  .moreProducts{
      background-color: $grey-50;
      color: $text-light-03;
      border-radius: 4px;
      height: $prodPicHeight;
      width: $prodPicHeight;
      line-height: $prodPicHeight;
      text-align: center;
    }
  }
}

.mainStyle{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
//     align-items:center;
}

.categ-title-wrapper{
    justify-content: space-between;
    align-items:center;
    @include max-S{
       flex-direction: column;
       align-items: unset;
    }
}
.sold-items{
    @include max-MD{
       flex-wrap: wrap;
    }
}
.facturi-fiscale{
   @include max-S{
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
}