
.comment-section {
  .comment-list {
    gap: 1.5rem;
    flex-wrap: wrap;
    display: flex;
  }
  .comment-item {
    width: 100%;
  }
  .comment-list-subtitle {
    margin-bottom: 0.5rem;
    @include ptype-3;
  }
  .remove-mark {
    @include ptype-3;
  }
  .comment-container {
    display: flex;
    padding-top: 0.75rem;
    padding: 1rem;
    background: $grey-10;
    border: 1px solid $grey-40;
    box-sizing: border-box;
    @include ptype-4;
  }
}

.applicant-page {
  .badgeTitle {
    margin-top: 8px;
    background-color: $grey-10;
    padding: 1rem;
    color: $text-light-01;
  }

  .badgeText {
    margin-top: 8px;
    background-color: $grey-10;
    padding: 1rem;
    color: $text-light-01;
  }

  .subtitle {
    margin-top: 16px;
    @extend %ptype-3;
    padding-top: 0.3rem;
    color: $text-light-01;
  }

  .job {
    @extend %ptype-5-strong;
    color: $text-light-01;
  }

  .actualizare {
    margin-top: 10px;
    margin-bottom: 20px;
    @extend %ptype-3;
    color: $text-light-01;
  }
  .magic-input .inputContainer {
    &.grey {
      background-color: $white;
      border: solid 1px #f3f3f3;
    }
    textarea {
      min-height: 77px;
      height: auto;
      background-color: $white;
      padding: 11px 0;
    }
  }
  .magic-button-container:not(.actionIcon) {
    &.primary,
    &.secondary {
      .label {
        font-weight: normal;
        white-space: nowrap;
        @extend %ptype-5;
        padding: 0;
      }
    }
  }
}

.magic-button-container {
  &.primary {
    font-weight: normal;
    white-space: nowrap;
    @extend %ptype-5;
    padding: 0;
    &.activeaza-button {
      min-width: 100%;
    }
  }
}

.magic-button-container {
  &.primary {
    &.outline {
      &.actionIcon {
        svg {
          path {
            stroke: $button-prime;
          }
        }
      }
    }
  }
}
