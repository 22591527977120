// @import 'style/colors';
// @import 'style/typography';

$tipSharpness: 0.8rem;
$bottom-tip-sharpness: 0.5rem;
@mixin tool-tip-general {
  position: relative;
  &:after {
    pointer-events: none;
    content: attr(tooltip);
    /* here's the magic */
    position: absolute;
    /* vertically center */
    background: $text-light-01;
    color: $white;
    @include ptype-4-price;
    white-space: nowrap;

    // display: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
    z-index: 10;
  }
  &:hover:after {
    opacity: 1;
  }
  &.show:after{
    opacity: 1;
  }
}
.button-tool-tip {
  &-left{
    @include tool-tip-general;
    &:after {
      right: 100%;
      top: 50%;
      transform: translate(0em, -50%);  
      padding: 0.5rem 1rem 0.5rem 0.75rem;
      clip-path: polygon(0% 0%, calc(100% - #{$tipSharpness}) 0%, 100% 50%, calc(100% - #{$tipSharpness}) 100%, 0% 100%);
      
    }
  }
  &-right{
    @include tool-tip-general;
    &:after {
      left: 100%;
      top: 50%;
      transform: translate(0em, -50%);  
      /* move to right */
      padding: 0.5rem 0.75rem 0.5rem 1rem;
      clip-path: polygon($tipSharpness 0%, 100% 0%, 100% 100%, $tipSharpness 100%, 0% 50%);
    }
  }
  &-bottom{
    @include tool-tip-general;
    &:after {
      max-width: 180px;
      white-space: normal;
      left: 50%;
      top: 100%;
      /* align arrow with the center of the object */
      // the tip will be 1 rem to the right of the card
      transform: translateX(calc(-1 * (1rem + #{$bottom-tip-sharpness})));  
      padding: 0.5rem 0.75rem;
      padding-top: calc(0.5rem + #{$bottom-tip-sharpness});
      clip-path: polygon(0% $bottom-tip-sharpness, 1rem $bottom-tip-sharpness, calc(1rem + #{$bottom-tip-sharpness}) 0%, calc(1rem + 2 * #{$bottom-tip-sharpness}) $bottom-tip-sharpness, 100% $bottom-tip-sharpness, 100% 100%, 0% 100%);
    }
    &.center{
        &:after{
            transform: translateX(-50%);  
            clip-path: polygon(0% $bottom-tip-sharpness, 
                calc(50% - #{$bottom-tip-sharpness}) $bottom-tip-sharpness, 
                calc(50%) 0%, 
                calc(50% + #{$bottom-tip-sharpness}) $bottom-tip-sharpness, 
                100% $bottom-tip-sharpness, 
                100% 100%, 0% 100%);
        }
    }
  }
}
